
/**
 * Constants
 */
export const CAMERA_Z_FAR = 945;
export const CAMERA_Z_NEAR = 540;
export const LAYER_ANIMATION_DURATION = 250;
export const SVG_SIZES = {
    width: 2040,
    height: 1020
};
export const markerIcons = [];
export const chainWideMarkerIcons = [];
export const inPhaseMarkerIcons = [];
export const phaseToMarkerDict = {
    'phase-one': [],
    'phase-two': [],
    'phase-three': [],
    'phase-four': []
};

/**
 * Container elements
 */
export const parentEl = document.querySelector('.asset-journey');
export const containerEl = document.querySelector('.canvas-container');

export const phaseZoomValues = {
    'phase-one': { x: SVG_SIZES.width * 0.33, y: -SVG_SIZES.height * 0.19, z: CAMERA_Z_NEAR },
    'phase-two': { x: SVG_SIZES.width * 0.85, y: -SVG_SIZES.height * 0.19, z: CAMERA_Z_NEAR },
    'phase-three': { x: SVG_SIZES.width * 0.85, y: -SVG_SIZES.height * 0.815, z: CAMERA_Z_NEAR },
    'phase-four': { x: SVG_SIZES.width * 0.33, y: -SVG_SIZES.height * 0.815, z: CAMERA_Z_NEAR },
    // 'phase-five': { x: SVG_SIZES.width * 0.375, y: -SVG_SIZES.height * 0.25, z: CAMERA_Z_NEAR },
};

export const challengesCheckbox = document.getElementById('challenges');
export const solutionsCheckbox = document.getElementById('solutions');


export const meshCollection = [] // Meshes will be stored here before being sorted into THREE groups

export const sizes = {
    width: containerEl.getBoundingClientRect().width,
    height: containerEl.getBoundingClientRect().height
}

export const navigationBar = document.querySelector('.navigation-block');

export const introDict = {
    "ketenbreed": null,
    "landingspagina": null,
    "planvorming": null,
    "planuitwerking": null,
    "beheer_en_onderhoud": null,
    "realisatie": null,
    "link_intranet": null,
};

export const overlay = document.querySelector('.overlay-layers__options');
export const middleButton = document.querySelector('.overlay__middle-button');
export const themeOverlay = document.querySelector('.overlay-thema');
export const phasesTitles = document.querySelector('.phases-titles__container');
export const intranetOverlay = document.querySelector('.overlay-intranet');

export const frontend_dev = import.meta.env.VITE_FRONTEND_DEV;
export const wagtail_dev_url = import.meta.env.VITE_WAGTAIL_DEV_URL;
export const canvas2 = document.querySelector('canvas');

export const typeDict = {
    "oplossing": "Oplossing",
    "uitdaging": "Uitdaging",
}
