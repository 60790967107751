import { gsap } from 'gsap';
import * as THREE from 'three';
import { SVG_SIZES, containerEl, frontend_dev, middleButton, navigationBar, parentEl, phaseZoomValues, phasesTitles, sizes, wagtail_dev_url } from "./constants";
import { buildLayerAsideHTML } from "./contentBuilder.js";
import { updateLayers } from './layers';

const navigationPhaseElements = document.querySelectorAll('.navigation-phase-one, .navigation-phase-two, .navigation-phase-three, .navigation-phase-four');

export let interfaceGroup = new THREE.Group(); // Group for the interface layout meshes

export function screenPositionToCanvasPosition(canvas, x, y) {
  // Given a mouse position in pixels, normalize it to a value between -1 and 1 on the canvas
  const canvasRect = canvas.getBoundingClientRect();
  return [x - canvasRect.left, y - canvasRect.top];
}

export function screenPositionToScenePosition(canvas, x, y) {
  // Given a mouse position in pixels, normalize it to a value between -1 and 1 on the canvas
  const canvasRect = canvas.getBoundingClientRect();
  const normalizedX = (x - canvasRect.left) / canvasRect.width;
  const normalizedY = (y - canvasRect.top) / canvasRect.height;
  return [normalizedX * 2 - 1, normalizedY * 2 - 1];
}

export function scenePositionToSVGPosition(scene, camera, x, y) {
  const raycaster = new THREE.Raycaster();

  // Create a Vector2 with normalized device coordinates (NDC)
  const screenPosition = new THREE.Vector2(x, y);

  // Set the origin of the raycaster based on the mouse position
  raycaster.setFromCamera(screenPosition, camera);

  // Perform the raycasting to find intersected objects
  const intersects = raycaster.intersectObjects(scene.children);

  if (intersects.length > 0) {
    // The first intersection point is where the ray hit an object
    const intersectionPoint = intersects[0].point;
    // const screenPosition = point.clone().project(camera);
    // const svgEdgePosition = new THREE.Vector3(, intersectionPoint.y);
    // ScenePointToCanvasPosition(intersectionPoint);
    const normalizedX = intersectionPoint.x / SVG_SIZES.width; // between 0-1
    const normalizedY = (intersectionPoint.y - 2 * camera.position.y) / SVG_SIZES.height; // between 0-1
    return [normalizedX, normalizedY];
  }

  return [0, 0];
}


export function ScenePointToCanvasPosition(canvas, camera, point) {
  const screenPosition = point.clone().project(camera);

  // Get the size of the canvas (renderer)
  const canvasWidth = canvas.offsetWidth;
  const canvasHeight = canvas.offsetHeight;

  // Convert the normalized device coordinates to screen coordinates
  const screenX = (screenPosition.x + 1) * canvasWidth / 2;
  const screenY = (screenPosition.y + 1) * canvasHeight / 2;

  // console.log('2D Camera Coordinates:', screenX, screenY);
  return [screenX, screenY];
}


export function SVGPositionToCanvasPosition(canvas, camera, x, y) {
  const point = new THREE.Vector3(x * SVG_SIZES.width, y * SVG_SIZES.height + 2 * camera.position.y, 0);
  return ScenePointToCanvasPosition(canvas, camera, point);
}

/**
 * Utility functions
 */
export const clearContainerClasses = () => {
  containerEl.classList.remove('phase-one-active');
  containerEl.classList.remove('phase-two-active');
  containerEl.classList.remove('phase-three-active');
  containerEl.classList.remove('phase-four-active');
}

export const closeAside = () => {
    parentEl.classList.remove('aside-open');
}

export const closeAsideOverlay = () => {
    parentEl.classList.remove('aside-overlay__open');
    parentEl.classList.remove('zoomed-out');
    parentEl.classList.remove('chain-wide');
}


export const fadeInterface = (opacity) => {
  interfaceGroup.children.forEach(mesh => {
    mesh.material.opacity = opacity
    // gsap.to(mesh.material, { opacity, duration: LAYER_ANIMATION_DURATION })
  });
}

export const camera = new THREE.PerspectiveCamera(57, sizes.width / sizes.height, 1, 10000);

let currentActivePhase = null;

let isZoomedIn = false;
let isZoomedOut = false;

// Zoom to phase
export const zoomPhase = (phase) => {
    const navigationPhase = document.querySelector(`.navigation-${phase}`);
    const { x, y, z } = phaseZoomValues[phase];

    const onComplete = () => {
        containerEl.classList.add(`${phase}-active`);

        updateLayers(canvas, camera);


        if (isZoomedOut) {
            parentEl.classList.add('aside-open');
        }

        isZoomedIn = true;
        isZoomedOut = false;
    };

    const onUpdate = () => {
        if (currentActivePhase !== phase) {
            if (currentActivePhase) {
                const previousNavigationPhase = document.querySelector(`.navigation-${currentActivePhase}`);
                if (previousNavigationPhase) {
                    previousNavigationPhase.classList.remove('active');
                }
            }
        }

        if (camera.position.z === 540) {
            navigationBar.style.display = 'flex';
            isZoomedIn = true;
        }

        currentActivePhase = phase;
        navigationPhase.classList.add(`active`);

    };

    const onStart = () => {

        if (camera.position.z === 945) {
            isZoomedOut = true;
            isZoomedIn = false;
            navigationBar.style.display = 'none';
        }

    };



    clearContainerClasses();
    fadeInterface(0);
    gsap.to(
        camera.position,
        { x, y, z, duration: 1, onComplete, onUpdate, onStart }
    );
};

navigationPhaseElements.forEach((element) => {
    const phase = element.dataset.phase;

    element.addEventListener('click', () => {
        if (phase) {
            buildLayerAsideHTML(phase);
            zoomPhase(phase);
            toggleVisibility(true);
            closeAsideOverlay();
        }
    });
});

export function getPhase(x, y) {
  let phase = null;
  switch (true) {
    case (x > 0 && x < 0.5 && y > 0 && y < 0.5):
      phase = 'phase-one';
      break;
    case (x > 0.5 && x < 1 && y > 0 && y < 0.5):
      phase = 'phase-two';
      break;
    case (x > 0.5 && x < 1 && y > 0.5 && y < 1):
      phase = 'phase-three';
      break;
    case (x > 0 && x < 0.5 && y > 0.5 && y < 1):
      phase = 'phase-four';
      break;
  }
  return phase;
}



export function hasPhase(phase) {
     return  ['phase-one','phase-two','phase-three','phase-four'].includes(phase);
}

export function toggleVisibility(show) {
    middleButton.style.display = show ? 'none' : 'flex';
    phasesTitles.style.display = show ? 'none' : 'flex';
}

// objects='markers' or objects='intros'
export function getUrl(objects) {

     if ((frontend_dev === "True") || (frontend_dev === 'true')) {
          return `${wagtail_dev_url}/api/joa/${objects}`
     }
     else {
          return `/api/joa/${objects}`
     }
}


export function getBase() {
     if ((frontend_dev === "True") || (frontend_dev === 'true')) {
          return `${wagtail_dev_url}/`;
     }
     return "";
}

export function setAttributes(el, attrs) {
  for(let key in attrs) {
    el.setAttribute(key, attrs[key]);
  }
}
