import { gsap } from "gsap";
import {
    CAMERA_Z_FAR,
    chainWideMarkerIcons,
    containerEl,
    introDict,
    LAYER_ANIMATION_DURATION,
    navigationBar,
    parentEl,
    phaseToMarkerDict,
    SVG_SIZES,
    themeOverlay,
    typeDict, markerIcons
} from "./constants.js";
import { camera, clearContainerClasses, closeAside, closeAsideOverlay, fadeInterface, getBase, setAttributes, toggleVisibility } from "./utils.js";


const themeDict = {
    "Sturing & monitoring": "splash",
    "Ketenbrede samenwerking": "collaborate",
    "Data ontsluiting & fundament": "document",
    "Vernieuwen en experimenteren": "lamp",
}

export const relatedLinks = [];

let dropdownWrapperElement = null;


const landingPageContent = 'Gemeente Amsterdam wil dat iedereen op een fijne manier gebruik kan maken van de openbare ruimte. Ondernemers, bewoners en bezoekers doen dat allemaal anders. Er is veel onderhoud en ontwikkeling nodig aan ieders wensen te voldoen. Nu en in de toekomst. Dat brengt grote uitdagingen met zich mee. Een deel van die uitdagingen worden digitaal opgelost. Hoe precies? Dat leggen we graag aan je uit in deze reis van een asset. Je volgt de fictieve transformatie van een stadspark met een aan te leggen ondergrondse fietsenstalling. Tijdens deze reis kom je onze uitdagingen en ontwikkelingen tegen. Je kunt altijd doorklikken voor meer informatie. Additional header We hebben een grote orderportefeuille met veel stakeholders. Of het nu gaat om een jarenlang en groot project of een reparatie, er zijn altijd meerdere directies betrokken. In die projecten reizen assets zoals bruggen, bomen, wegen et cetera door de keten van planvorming, ontwerp, realisatie en beheer. Dit is ons primaire werkproces. De samenwerking in die hele keten tussen verschillende directies is uitdagend. Daarom zijn we op zoek gegaan naar verbeteringen in het proces. Alle betrokken directies en het I-domein werken samen in de digitaliseringsopgave Stedelijke ontwikkeling en beheer (SOB).';

const intranetLinkContent = 'Hier opvullen met content over intranet';

const ketenbreed = {
    title: "Ketenbreed"
}

const layerAsideLookupTable = {
    'phase-one': {
        'id': 'planvorming',
        'label': 'Fase 1',
        'title': 'Planvorming',
        'text': ' Due to the growing demand for a new bicycle parking lot and the need to renovate the existing park, the project team enters a Planvorming Fase where they explore different sketch options and assess the feasibility of the project. This phase also marks the start of the budgeting process.'
    },
    'phase-two': {
        'id': 'planuitwerking',
        'label': 'Fase 2',
        'title': 'Planuitwerking',
        'text': ' Due to the growing demand for a new bicycle parking lot and the need to renovate the existing park, the project team enters a Planvorming Fase where they explore different sketch options and assess the feasibility of the project. This phase also marks the start of the budgeting process.'
    },
    'phase-three': {
        'id': 'realisatie',
        'label': 'Fase 3',
        'title': 'Realisatie',
        'text': ' Due to the growing demand for a new bicycle parking lot and the need to renovate the existing park, the project team enters a Planvorming Fase where they explore different sketch options and assess the feasibility of the project. This phase also marks the start of the budgeting process.'
    },
    'phase-four': {
        'id': 'beheer_en_onderhoud',
        'label': 'Fase 4',
        'title': 'Beheer & Onderhoud',
        'text': ' Due to the growing demand for a new bicycle parking lot and the need to renovate the existing park, the project team enters a Planvorming Fase where they explore different sketch options and assess the feasibility of the project. This phase also marks the start of the budgeting process.'
    }
};


export function buildMarkerOverlayHTML(markerJson) {
    let innerHTML = `
        <span class="aside__header-label">${typeDict[markerJson.type]}</span>
        <div class="aside__header-title">${markerJson.description}</div>
        <span class="icon icon__span close overlay marker_close"></span>
    `;

    let contentText = [];

    markerJson.body.forEach((block) => {
        if (block.type === "ContentBlock") {
            contentText.push(
                `<div class="aside__body-text">${block.value.content}</div>`
            );
        }
        if (block.type === "VideoBlock") {
            const videoProvider = block.value.provider;
            const videoId = block.value.video_id;

            if (videoProvider === "yt") {
                // YouTube video
                contentText.push(`
                    <iframe
                        src="https://www.youtube.com/embed/${videoId}"
                        frameborder="0"
                        allowfullscreen
                    ></iframe>
                `);
            } else if (videoProvider === "vm") {
                // Vimeo video
                contentText.push(`
                    <iframe
                        src="https://player.vimeo.com/video/${videoId}"
                        frameborder="0"
                        allowfullscreen
                    ></iframe>
                `);
            }
        }
        if (block.type === "ImageBlock") {
            let image = block.value;
            contentText.push(
                `<img class="aside_image" src=${getBase()}${image.url} alt=${image.alt} width=${image.width} height=${image.height}>`
            );
        }
        if (block.type === "LinksBlock") {
            block.value.external_links.forEach((externalLink) => {
                const existingLink = relatedLinks.find(link => link.href === externalLink.href);
                if (!existingLink) {
                    relatedLinks.push(
                        {
                            "href": externalLink.href,
                            "title": externalLink.title,
                            "target": externalLink.target
                        }
                    );
                }
            });
        }
    });

    innerHTML += contentText.join('');

    return innerHTML;
}

export function buildLayerAsideHTML(phase) {

    const layerAsideEl = document.querySelector('.layer-aside');
    layerAsideEl.innerHTML = "";

    const spanLabelElement = document.createElement('span');
    spanLabelElement.classList.add('aside__header-label');
    spanLabelElement.innerHTML = layerAsideLookupTable[phase].label;
    layerAsideEl.appendChild(spanLabelElement);

    const divTitleElement = document.createElement('div');
    divTitleElement.classList.add('aside__header-title');
    divTitleElement.innerHTML = layerAsideLookupTable[phase].title;

    layerAsideEl.appendChild(divTitleElement);

    const divTextElement = document.createElement('div');
    divTextElement.classList.add('aside__body-text');

    if (introDict[layerAsideLookupTable[phase].id] !== null) {
        divTextElement.innerHTML = introDict[layerAsideLookupTable[phase].id];
    } else {
        divTextElement.innerHTML = layerAsideLookupTable[phase].text;
    }
    layerAsideEl.appendChild(divTextElement);
    layerAsideEl.appendChild(returnDropDownElement(phase, "Uitdagingen & oplossingen"));
    layerAsideEl.appendChild(returnDropDownListElement(phase));
    layerAsideEl.appendChild(returnToChainWideOverlayLink());

    const spanCloseElement = document.createElement('span');
    spanCloseElement.setAttribute('id', 'close-aside');
    spanCloseElement.classList.add("icon", "icon__span", "close");
    layerAsideEl.appendChild(spanCloseElement);

    spanCloseElement.addEventListener('click', () => {
        navigationBar.style.display = 'none';
        clearContainerClasses();
        closeAside();

        fadeInterface(1);
        setTimeout(() => {
            gsap.to(
                camera.position,
                {x: SVG_SIZES.width / 2, y: -SVG_SIZES.height / 2, z: CAMERA_Z_FAR, duration: 1,
                onComplete: () => {
                    toggleVisibility(false);
                    spanCloseElement.style.pointerEvents = 'auto';
                    containerEl.style.pointerEvents = 'auto';
                },
                onStart: () => {
                    spanCloseElement.style.pointerEvents = 'none';
                    containerEl.style.pointerEvents = 'none';

                },
                onUpdate: () => {
                    if (camera.position.z > 540) {
                        navigationBar.style.display = 'none';
                    }
                }
            });
        }, LAYER_ANIMATION_DURATION );
    });
}



export function returnToChainWideOverlayLink() {
    const anchorTagElement = document.createElement('a');
    anchorTagElement.classList.add('aside__header-link');
    anchorTagElement.setAttribute('href', "#");
    anchorTagElement.innerText = "Ketenbrede uitdagingen & oplossingen";
    anchorTagElement.addEventListener('click', showChainWideOverlay);
    anchorTagElement.chainWide = "chain-wide";
    return anchorTagElement;
}


function makeUnorderedListWithLinks(phase, markerId = null) {

    const unorderedListElement = document.createElement('ul');

    if (markerId !== null) {
        let markerIcon = markerIcons.find((element) => element.id === markerId)

        markerIcon.relations.forEach((relatedMarker) => {
            let relatedMarkerIcon = markerIcons.find((element) => element.id === relatedMarker.id)
            const listElement = relatedMarkerIcon.returnClickableListElement();
            unorderedListElement.appendChild(listElement);
        })
    } else {
        phaseToMarkerDict[phase].forEach((markerIcon) => {
            // let markerIcon = markerIcons.find((element) => element.id === markerId)
            const listElement = markerIcon.returnClickableListElement();
            unorderedListElement.appendChild(listElement);
        });
    }

    if (unorderedListElement.children.length > 0) {
        if (dropdownWrapperElement) {
            dropdownWrapperElement.style.display = 'block';
        }
    } else {
        if (dropdownWrapperElement) {
            dropdownWrapperElement.style.display = 'none';
        }
    }

    return unorderedListElement;
}

function makeChainWideList(typeChallenge, typeSolution) {

    const unorderedListElement = document.createElement('ul');
    unorderedListElement.classList.add('chainwide-list');

    chainWideMarkerIcons.forEach((markerIcon) => {

            if (markerIcon.type === typeChallenge || markerIcon.type === typeSolution) {
                const listElement = markerIcon.returnClickableListElement();
                unorderedListElement.appendChild(listElement);
            }
        }
    );


    return unorderedListElement;
}


export function returnDropDownListElement(phase, markerId = null) {

    const dropdownListElement = document.createElement('div');
    dropdownListElement.classList.add('dropdown-list');

    dropdownListElement.appendChild(makeUnorderedListWithLinks(phase, markerId));
    return dropdownListElement;

}


export function returnDropDownElement(phase, dropdownText = "", markerId = null) {

    // create dropdown wrapper
    dropdownWrapperElement = document.createElement('div');
    dropdownWrapperElement.classList.add('dropdown-wrapper');

    // create dropdown title
    const dropdownTitleElement = document.createElement('div');
    dropdownTitleElement.classList.add('dropdown-title');

    const dropdownTextElement = document.createTextNode(dropdownText);
    const spanArrowDownElement = document.createElement('span');
    spanArrowDownElement.classList.add("icon", "icon__span", "arrow-down");
    const spanArrowUpElement = document.createElement('span');
    spanArrowUpElement.classList.add("icon", "icon__span", "arrow-up");

    dropdownTitleElement.appendChild(dropdownTextElement);
    dropdownTitleElement.appendChild(spanArrowDownElement);
    dropdownTitleElement.appendChild(spanArrowUpElement);

    dropdownWrapperElement.appendChild(dropdownTitleElement);

    // create dropdown lists
    const dropdownListElement = returnDropDownListElement(phase, markerId);
    dropdownWrapperElement.appendChild(dropdownListElement);

    dropdownTitleElement.addEventListener('click', () => {

        dropdownListElement.classList.toggle('active');
        // dropdownListElement2.classList.toggle('active');

        if (spanArrowUpElement.style.display === 'inline-flex') {
            spanArrowUpElement.style.display = 'none';
            spanArrowDownElement.style.display = 'inline-flex';
        } else {
            spanArrowUpElement.style.display = 'inline-flex';
            spanArrowDownElement.style.display = 'none';
        }
    });

    return dropdownWrapperElement;

}

// type = "uitdaging" or "oplossing", className = "uitdaging" or "oplossing", name = "Uitdagingen" or "Oplossingen"
export function returnChainWideWrapperElement(typeChallenge, typeSolution, name) {

    // create chain wide wrapper
    const chainWideWrapperElement = document.createElement('div');
    chainWideWrapperElement.classList.add('chainwide-wrapper');

    // create dropdown title
    const chainWideTitleElement = document.createElement('div');
    chainWideTitleElement.classList.add("chainwide-title");

    const textElement = document.createTextNode(name);

    const spanArrowDownElement = document.createElement('span');
    spanArrowDownElement.classList.add("icon", "icon__span", "arrow-down");
    const spanArrowUpElement = document.createElement('span');
    spanArrowUpElement.classList.add("icon", "icon__span", "arrow-up");

    chainWideTitleElement.appendChild(textElement);
    chainWideTitleElement.appendChild(spanArrowDownElement);
    chainWideTitleElement.appendChild(spanArrowUpElement);

    chainWideWrapperElement.appendChild(chainWideTitleElement);

    // create chain wide list
    const chainWideListElement = makeChainWideList(typeChallenge, typeSolution);

    chainWideWrapperElement.appendChild(chainWideListElement);

    chainWideTitleElement.addEventListener('click', () => {

        chainWideListElement.classList.toggle('active');

        if (spanArrowUpElement.style.display === 'inline-flex') {
            spanArrowUpElement.style.display = 'none';
            spanArrowDownElement.style.display = 'inline-flex';
        } else {
            spanArrowUpElement.style.display = 'inline-flex';
            spanArrowDownElement.style.display = 'none';
        }
    });

    return chainWideWrapperElement;

}


export function returnThemesElement(themes) {

    const themesContainerElement = document.createElement('div');
    const headerElement = document.createElement('h3');
    themesContainerElement.classList.add('aside__themes');

    headerElement.innerHTML = "Thema";
    themesContainerElement.appendChild(headerElement);

    const unorderedListElement = document.createElement('ul');

    themes.forEach((theme) => {
        const iconElement = document.createElement('span');
        iconElement.classList.add("icon", "icon__span", `${themeDict[theme]}`);
        const listElement = document.createElement('li');
        listElement.appendChild(iconElement);
        const themeNameTextNode = document.createTextNode(theme);
        listElement.appendChild(themeNameTextNode);

        listElement.addEventListener('click', () => {
            themeOverlay.style.display = themeOverlay.style.display === 'block' ? 'none' : 'block';
        });

        unorderedListElement.appendChild(listElement);
    });
    themesContainerElement.appendChild(unorderedListElement);
    return themesContainerElement;
}

export function returnRelatedLinksElement() {
    const linksContainerElement = document.createElement('div');
    const headerElement = document.createElement('div');
    linksContainerElement.classList.add('dropdown-wrapper');
    headerElement.classList.add('dropdown-title');

    const spanArrowDownElement = document.createElement('span');
    spanArrowDownElement.classList.add("icon", "icon__span", "arrow-down");
    const spanArrowUpElement = document.createElement('span');
    spanArrowUpElement.classList.add("icon", "icon__span", "arrow-up");

    headerElement.innerHTML = "Lees meer";
    linksContainerElement.appendChild(headerElement);
    headerElement.appendChild(spanArrowDownElement);
    headerElement.appendChild(spanArrowUpElement);

    const unorderedListElement = document.createElement('ul');
    unorderedListElement.classList.add('dropdown-list');

    headerElement.addEventListener('click', () => {
        unorderedListElement.classList.toggle('active');
        spanArrowUpElement.style.display = spanArrowUpElement.style.display === 'inline-flex' ? 'none' : 'inline-flex';
        spanArrowDownElement.style.display = spanArrowDownElement.style.display === 'inline-flex' ? 'inline-flex' : 'none';
    });

    relatedLinks.forEach((relatedLink) => {
        const listElement = document.createElement('li');
        const tagElement = document.createElement('a');
        // Assuming setAttributes is a function that sets attributes on an element
        setAttributes(tagElement, relatedLink);
        tagElement.innerHTML = relatedLink.title;

        const iconBeforeElement = document.createElement('span');
        iconBeforeElement.classList.add("icon", "icon__span", "arrow-right");
        listElement.append(iconBeforeElement);

        listElement.appendChild(tagElement);
        unorderedListElement.appendChild(listElement);

        const iconAfterElement = document.createElement('span');
        iconAfterElement.classList.add("icon", "icon__span", "external", "link");
        listElement.append(iconAfterElement);
    });

    linksContainerElement.appendChild(unorderedListElement);

    // Clear the relatedLinks array after it has been used
    relatedLinks.length = 0;

    return linksContainerElement;
}


export function showChainWideOverlay(param) {
    containerEl.style.pointerEvents = 'auto';
    const closeSideBar = document.createElement('span');
    closeSideBar.classList.add("icon", "icon__span", "close", "overlay");
    const zoomedOut = document.querySelector('.zoomed-out');
    if (closeSideBar && zoomedOut) {
        closeSideBar.addEventListener('click', () => {
            closeAsideOverlay();
            containerEl.style.pointerEvents = 'auto';
        });
    }

    if (closeSideBar && (param && param.currentTarget && param.currentTarget.chainWide)) {
        const clickedChainWide = document.querySelector(".asset-journey")
        clickedChainWide.classList.add("chain-wide")
    }

    const chainWide = document.querySelector('.chain-wide');

    if (closeSideBar && chainWide) {
        closeSideBar.addEventListener('click', () => {
            closeAsideOverlay();
            containerEl.style.pointerEvents = 'auto';
        });
    }

    const dataDisplay = document.querySelector('.aside-overlay');
    dataDisplay.innerHTML = "";
    const divTitleElement = document.createElement('div');
    divTitleElement.classList.add('aside__header-title');
    divTitleElement.innerHTML = ketenbreed.title;

    const divTextElement = document.createElement('div');
    divTextElement.classList.add('aside__body-text');
    divTextElement.innerHTML = introDict["ketenbreed"];

    dataDisplay.appendChild(divTitleElement);
    dataDisplay.appendChild(divTextElement);
    dataDisplay.appendChild(closeSideBar);


    // type = "uitdaging" or "oplossing", className = "uitdaging" or "oplossing", name = "Uitdagingen" or "Oplossingen"
    dataDisplay.appendChild(returnChainWideWrapperElement("uitdaging", "oplossing", "Uitdagingen & oplossingen"));

    parentEl.classList.add('aside-overlay__open');

    return false;

}

export function addContentToLandingPage() {

        const landingPageInfoBody = document.getElementById("landing_page_info_body");
        if (("landingspagina" in introDict ) && (introDict["landingspagina"] !== null)) {
                        landingPageInfoBody.innerHTML = introDict["landingspagina"];
        } else {
            landingPageInfoBody.innerHTML = landingPageContent;
        }
        const overlayLandingPage = document.getElementById('overlay-landing-page');
        overlayLandingPage.style.visibility = "visible";

}

export function addContentToIntanet() {

    const intranetLinkBody = document.querySelector(".overlay-intranet__body");
    if (("link_intranet" in introDict) && (introDict["link_intranet"] !== null)) {
        intranetLinkBody.innerHTML = introDict["link_intranet"];
    } else {
        intranetLinkBody.innerHTML = intranetLinkContent;
    }
}
