import * as THREE from 'three';
import { InteractionManager } from 'three.interactive';
import { SVGLoader } from 'three/addons/loaders/SVGLoader.js';


import {
    CAMERA_Z_FAR,
    canvas2,
    challengesCheckbox,
    containerEl,
    intranetOverlay,
    middleButton,
    overlay,
    parentEl,
    sizes,
    solutionsCheckbox,
    SVG_SIZES,
    themeOverlay
} from './constants';
import { buildLayerAsideHTML, showChainWideOverlay } from "./contentBuilder.js";
import { fetchLayers, updateLayers } from './layers';
import { mockLayers } from "./mockdata.js";
import {
    camera,
    closeAsideOverlay,
    getPhase,
    scenePositionToSVGPosition,
    screenPositionToCanvasPosition,
    screenPositionToScenePosition,
    toggleVisibility,
    zoomPhase
} from './utils';


const toggleButton = document.getElementById('toggleBtn');
const startButton = document.querySelector('.overlay-info__button');
const homeScreen = document.querySelector('.home-screen__container');
const journeyScreen = document.querySelector('.asset-journey__container');
export const overlayIntro = document.querySelector('.overlay-info');
const themeLink = document.querySelector('.header__theme-link');
const intranetLink = document.querySelector('.header__intranet-link');
const closeThemeOverlay = document.querySelector('.icon__span.close.theme');
const closeIntranetOverlay = document.querySelector('.icon__span.close.intranet');
const intranetLinkFromOverlay = document.querySelector('.overlay-info__link')

document.addEventListener('DOMContentLoaded', () => {
    const closeOverlaySideBar = document.querySelector('.icon__span.close.overlay');
    if (closeOverlaySideBar) {
        closeOverlaySideBar.addEventListener('click', () => {
            closeAsideOverlay();
            parentEl.classList.remove('zoomed-out');
        });
    }

    startButton.addEventListener('click', () => {
        overlayIntro.classList.toggle('active')
        homeScreen.style.display = 'none';
        journeyScreen.style.visibility = 'visible';
        overlay.style.display = 'none';
    });

    middleButton.addEventListener('click',  () => {
        parentEl.classList.add('aside-overlay__open', 'zoomed-out');
        showChainWideOverlay();
    });


    toggleButton.addEventListener('click', () => {
        overlay.style.display = overlay.style.display === 'block' ? 'none' : 'block';
        toggleButton.classList.toggle('active');
    });

    themeLink.addEventListener('click', (e) => {
        e.preventDefault();
        themeOverlay.style.display = themeOverlay.style.display === 'block' ? 'none' : 'block';
    });

    intranetLink.addEventListener('click', (e) => {
        e.preventDefault();
        intranetOverlay.classList.toggle('active')
    });

    intranetLinkFromOverlay.addEventListener('click', (e) => {
        e.preventDefault();
        intranetOverlay.classList.toggle('active')
    });

    closeThemeOverlay.addEventListener('click', () => {
        themeOverlay.style.display = 'none';
    });

    closeIntranetOverlay.addEventListener('click', () => {
        intranetOverlay.classList.toggle('active')
    });

    solutionsCheckbox.addEventListener('change', updateButtonVisibility);
    challengesCheckbox.addEventListener('change', updateButtonVisibility);

    const headerTitleElement = document.getElementById('header-title');
      headerTitleElement.addEventListener('click', () => {
        window.location.reload();
      });

});

function updateButtonVisibility() {
    if (solutionsCheckbox.checked || challengesCheckbox.checked) {
        middleButton.style.visibility = 'visible';
    } else {
        middleButton.style.visibility = 'hidden';
    }
}




/**
 * THREE BASE CONFIGURATION
 */

const svgGroup = new THREE.Group(); // The main SVG group


/**
 * Debug tools
 */
// const gui = new dat.GUI()
// const guiObject = {
//     'Reset': () => {
//         clearContainerClasses()
//         closeAside()
//         fadeInterface(1)
//         setTimeout(() => {
//             gsap.to(
//                 camera.position,
//                 { x: SVG_SIZES.width / 2, y: - SVG_SIZES.height / 2, z: CAMERA_Z_FAR, duration: 1 }
//             )
//         }, LAYER_ANIMATION_DURATION)
//     },
//     '1. Planvorming': () => zoomPhase('phase-one'),
//     '2. Planuitwerking': () => zoomPhase('phase-two'),
//     '3. Realisatie': () => zoomPhase('phase-three'),
//     '4. Beheer & onderhoud': () => zoomPhase('phase-four'),
//     'Open aside': () => {
//         parentEl.classList.add('aside-open')
//     },
//     'Close aside': () => {
//         parentEl.classList.remove('aside-open')
//     }
// }

// gui.add(guiObject, 'Reset')
// gui.add(guiObject, '1. Planvorming')
// gui.add(guiObject, '2. Planuitwerking')
// gui.add(guiObject, '3. Realisatie')
// gui.add(guiObject, '4. Beheer & onderhoud')
// gui.add(guiObject, 'Open aside')
// gui.add(guiObject, 'Close aside')

// Canvas
const canvas = document.querySelector('canvas.webgl');

// Scene
const scene = new THREE.Scene();
scene.background = new THREE.Color(0xffffff)

/**
 * Loading Manager
 * Taken from THREE SVG Loader example: https://github.com/mrdoob/three.js/blob/master/examples/webgl_loader_svg.html
 */
const onLoad = (data) => {
    // console.log('SVG Loader onLoad ::: ', data);

    const paths = data.paths; // The svg paths

    svgGroup.scale.y *= -1; // Flip the SVG upside down

    // Create a mesh from each path
    for (let i = 0; i < paths.length; i++) {
        const path = paths[i];
        let fillColor = path.userData.style.fill;

        // If path has a fill color
        if (fillColor !== undefined && fillColor !== 'none') {

            const material = new THREE.MeshBasicMaterial({
                color: new THREE.Color().setStyle(fillColor),
                opacity: path.userData.style.fillOpacity,
                transparent: true,
                depthWrite: false,
            });

            const shapes = SVGLoader.createShapes(path); // A path could consist of multiple shapes

            for (let j = 0; j < shapes.length; j++) {

                const shape = shapes[j];

                const geometry = new THREE.ShapeGeometry(shape);
                const mesh = new THREE.Mesh(geometry, material);

                // Recursively build up hierarchy
                // The idea was to have THREE.Group for each '<g>' tag of the SVG, but I think that's not in scope yet
                // For now I'll leave the code so each mesh has a hierarchy array that could be used later
                if (path.userData.node && path.userData.node.parentNode) {
                    const hierarchy = []
                    let level = 0

                    const registerHierarchy = (node) => {
                        if (node.id.includes('total')) return

                        if (level > 0 && !node.id) {
                            console.error('Could not generate hierarchy, no id found for node in the hierarchy chain ::: ', node)
                        }

                        level++
                        hierarchy.push(node.id)
                        node.parentNode && registerHierarchy(node.parentNode)
                    }

                    path.userData.node && path.userData.node.parentNode && registerHierarchy(path.userData.node.parentNode)
                    mesh.userData.hierarchy = hierarchy
                }

                // // Use interaction manager to make things clickable
                // interactionManager.add( mesh )
                // mesh.addEventListener('click', (event) => {
                //     clearContainerClasses()
                //     const { x, y } = event.target.geometry.boundingSphere.center
                //     gsap.to(camera.position, { x, y: -y, z: 450, duration: .5 })
                // });

                // mesh.addEventListener('mouseover', (event) => {
                //     document.body.style.cursor = 'pointer'
                // });

                // mesh.addEventListener('mouseout', (event) => {
                //     document.body.style.cursor = 'default';
                // });

                // Add to the main group
                svgGroup.add(mesh)
            }

        }
    }

    // Don't forget to add things to the scene!
    scene.add(svgGroup)
}

const onError = (e) => {
    console.log('SVG Load Error ::: ', e)
}

/**
 * SVG loader
 */
const svgLoader = new SVGLoader()
svgLoader.load(
    'assets/svg/phases_with_interface.svg',
    onLoad,
    () => {
    },
    onError
)

/**
 * Lights
 */
const ambientLight = new THREE.AmbientLight(0xffffff, 0.6)
scene.add(ambientLight)

// /**
//  * Sizes
//  */
// const sizes = {
//     width: containerEl.getBoundingClientRect().width,
//     height: containerEl.getBoundingClientRect().height
// }

/**
 * Camera
 */
camera.position.set(SVG_SIZES.width / 2, -SVG_SIZES.height / 2, CAMERA_Z_FAR);
camera.lookAt(SVG_SIZES.width / 2, -SVG_SIZES.height / 2, 0)
camera.updateProjectionMatrix()

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Interaction Manager
 */
const interactionManager = new InteractionManager(
    renderer,
    camera,
    renderer.domElement
);

// Create an infinite white plane
const planeGeometry = new THREE.PlaneGeometry(10000, 10000);
const planeMaterial = new THREE.MeshBasicMaterial({color: 0xffffff});
const plane = new THREE.Mesh(planeGeometry, planeMaterial);
// plane.rotation.x = -Math.PI / 2; // Rotate the plane to be horizontal
scene.add(plane);

fetchLayers(canvas, camera);


// Function to attach event listener when the target element exists
function closeOverlaySideBarWhenExist() {
    const observer = new MutationObserver(() => {
        const closeOverlaySideBar = document.querySelector('.icon__span.close.overlay');
        const closeMarkerOverlaySideBar = document.querySelector('.icon__span.close.overlay.marker_close')
        const zoomedOut = document.querySelector('.zoomed-out');
        const chainWide = document.querySelector('.chain-wide');

        // if (closeOverlaySideBar && zoomedOut) {
        if (closeMarkerOverlaySideBar && zoomedOut) {
            closeMarkerOverlaySideBar.addEventListener('click', () => {
                containerEl.style.pointerEvents = 'auto';
                showChainWideOverlay();
            });
        }

        if (closeMarkerOverlaySideBar && chainWide) {
            closeMarkerOverlaySideBar.addEventListener('click', () => {
                containerEl.style.pointerEvents = 'auto';
                showChainWideOverlay();
            });
        }

        if (closeOverlaySideBar && (!zoomedOut && !chainWide)){
            closeOverlaySideBar.addEventListener('click', () => {
                closeAsideOverlay();
            });
        }
    });

    // Start observing changes in the DOM
    observer.observe(document.body, {childList: true, subtree: true});
}

closeOverlaySideBarWhenExist();

// // Attach event listener directly for elements that are already present
// document.addEventListener('DOMContentLoaded', () => {
//     const closeOverlaySideBar = document.querySelector('.icon__span.close.overlay');
//     if (closeOverlaySideBar) {
//         closeOverlaySideBar.addEventListener('click', () => {
//             closeAsideOverlay();
//         });
//     }
// });

/**
 * Resize handler
 */
const resizeHandler = (event) => {
    const previousSize = {...sizes}
    const {width, height} = containerEl.getBoundingClientRect()

    // Update sizes
    sizes.width = width
    sizes.height = height

    // Calc scale
    const xScale = sizes.width / previousSize.width
    const yScale = sizes.height / previousSize.height

    svgGroup.scale.set(xScale, -yScale, 1)

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
}
window.addEventListener('resize', resizeHandler)

/**
 * Animate
 */
const clock = new THREE.Clock()
let previousTime = 0

const tick = () => {
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime

    // Update IM
    interactionManager.update();

    // Render
    renderer.render(scene, camera);
    updateLayers(canvas, camera);


    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()

const updateMockLayersPosition = (phase) => {

    const parent = document.querySelector('.canvas-container');
    if (!parent) return;

    mockLayers.forEach(layer => {
        if (layer.phase === phase) {
            const icon = new LayerIcon({...layer});

            const {width, height} = parent.getBoundingClientRect();
            const adjustedX = width * layer.position.x + x;
            const adjustedY = height * layer.position.y + y;

            icon.element.style.transform = `translate(${adjustedX}px, ${adjustedY}px)`;

            parent.appendChild(icon.element);

        }
    });
};


// Add a mouse click event listener to the canvas
canvas2.addEventListener('click', onMouseClick);


function onMouseClick(event) {
    // Get the mouse coordinates relative to the canvas
    const mouseX = event.clientX;
    const mouseY = event.clientY;

    // console.log('Mouse Position', mouseX, mouseY);
    const [canvasX, canvasY] = screenPositionToCanvasPosition(canvas, mouseX, mouseY);
    // console.log('Scene Position from Canvas position', canvasX, canvasY);
    const [sceneX, sceneY] = screenPositionToScenePosition(canvas, mouseX, mouseY);
    // console.log('Scene Position from Mouse position', sceneX, sceneY);
    const [svgx, svgy] = scenePositionToSVGPosition(scene, camera, sceneX, sceneY);
    // svg position
    console.log("X position:", Math.round(svgx * 100) / 100, 'Y position:', Math.round(svgy * 100) / 100 );
    // console.log('Canvas position from SVG position', SVGPositionToCanvasPosition(canvas, camera, svgx, svgy));

    let phase = getPhase(svgx, svgy);
    if (phase !== null) {
        buildLayerAsideHTML(phase);
        zoomPhase(phase);
        toggleVisibility(true);
    }
}
