import {
    chainWideMarkerIcons,
    challengesCheckbox,
    markerIcons,
    introDict,
    inPhaseMarkerIcons,
    phaseToMarkerDict,
    solutionsCheckbox,
    typeDict
} from "./constants";
import {
    addContentToIntanet,
    addContentToLandingPage,
    buildLayerAsideHTML,
    buildMarkerOverlayHTML, relatedLinks,
    returnDropDownElement, returnRelatedLinksElement,
    returnThemesElement,
    returnToChainWideOverlayLink,
} from "./contentBuilder.js";
import {
    SVGPositionToCanvasPosition,
    getPhase,
    getUrl,
    hasPhase,
    toggleVisibility,
    zoomPhase
} from "./utils";


class MarkerIcon {
    constructor({
                    id,
                    phase,
                    url,
                    description,
                    chainWide,
                    type,
                    themes,
                    position,
                    relations
                }) {
        this.open = false;

        this.id = id;
        this.phase = phase;
        this.url = url;
        this.description = description;
        this.chainWide = chainWide;
        this.themes = themes;
        this.type = type;
        this.position = position;
        this.relations = relations

        this.wrapper = document.querySelector('.asset-journey');
        this.realX = 0;
        this.realY = 0;

        if (!this.wrapper) {
            return;
        }

        this._createElements(description);
        return this;
    }

    changeRealPosition(x, y) {
        // if (this.realX === x && this.realY === y) return
        // this.realX = x;
        // this.realY = y;

        if(!this.chainWide) {

            if( (this.position.x > 0.25 && this.position.x < 0.5) || (this.position.x > 0.75 && this.position.x < 1)) {
                this.element.style.transform = `translate(-100%, 0%) translate(${x}px, ${y}px)`;
            } else {
                this.element.style.transform = `translate(${x}px, ${y}px)`;

        }
    }


    }

    returnClickableListElement() {
        const listItemElement = document.createElement('li');
        const spanElement = document.createElement('span');

        spanElement.classList.add("icon", "icon__span", "arrow-right");
        listItemElement.append(spanElement);

        const text = document.createTextNode(`${typeDict[this.type]}: ${this.description}`);

        listItemElement.appendChild(text);
        listItemElement.addEventListener('click', () => {
            const willOpen = true;
            this.wrapper.classList.toggle('aside-overlay__open', willOpen);
            this.open = willOpen;

            const dataDisplay = document.querySelector('.aside-overlay');
            dataDisplay.innerHTML = "";

            fetch(this.url)
                .then(response => response.json())
                .then(markerJson => {

                    dataDisplay.innerHTML = buildMarkerOverlayHTML(markerJson);
                    dataDisplay.appendChild(returnThemesElement(markerJson.themes));
                    dataDisplay.appendChild(
                        returnDropDownElement(this.phase, "Gerelateerd", this.id)
                    );
                    if (relatedLinks.length > 0) {
                        dataDisplay.appendChild(returnRelatedLinksElement());
                    }
                    dataDisplay.appendChild(returnToChainWideOverlayLink());
                    if (this.phase !== null) {
                        zoomPhase(this.phase);
                        toggleVisibility(true);
                    }
                })
                .catch(error => {
                    console.log(`error: ${error}`);
                });
        });
        return listItemElement;
    }

    _createElements(description) {
        this.element = document.createElement('button');
        this.element.classList.add("layer-icon",`${this.type}`);

        const iconEl = document.createElement('span');
        iconEl.classList.add('icon__span', this.type);

        const descriptionEl = document.createElement('span');
        descriptionEl.textContent = description;
        descriptionEl.classList.add('layer-icon__description');

        this.element.appendChild(iconEl);
        this.element.appendChild(descriptionEl);

        this._initToggle();
    }

    _initToggle() {
        this.element.addEventListener('click', () => {
            const willOpen = true;
            this.wrapper.classList.toggle('aside-overlay__open', willOpen);
            this.open = willOpen;

            const dataDisplay = document.querySelector('.aside-overlay');
            dataDisplay.innerHTML = "";

            fetch(this.url)
                .then(response => response.json())
                .then(markerJson => {

                    dataDisplay.innerHTML = buildMarkerOverlayHTML(markerJson);
                    dataDisplay.appendChild(returnThemesElement(markerJson.themes));

                    const dropdownListElement = returnDropDownElement(this.phase, "Gerelateerd", this.id);

                    if (dropdownListElement.textContent.trim() !== "Gerelateerd") {
                        dataDisplay.appendChild(dropdownListElement);
                    }

                    if (relatedLinks.length > 0) {
                        dataDisplay.appendChild(returnRelatedLinksElement());
                    }
                    dataDisplay.appendChild(returnToChainWideOverlayLink());

                    if (this.phase !== null) {
                        buildLayerAsideHTML(this.phase);
                        zoomPhase(this.phase);
                        toggleVisibility(true);
                    }
                })
                .catch(error => {
                    console.log(`error: ${error}`);
                });
        });
    }
}

// fetch mockData

// export function fetchLayers (canvas, camera) {
//     const parent = document.querySelector('.canvas-container')
//     if (!parent) return

//     mockLayers.forEach(layer => {
//         const icon = new MarkerIcon({ ...layer })

//         const [x, y] = SVGPositionToCanvasPosition(canvas, camera, layer.position.x, layer.position.y)
//         icon.changeRealPosition(x, y);

//         parent.appendChild(icon.element)
//         // console.log(icon)

//         markerIcons.push(icon);

//         // if (challengesCheckbox.checked && layer.type == 'challenge') {
//         //   parent.appendChild(icon.element);
//         // }
//         // else if (solutionsCheckbox.checked && layer.type == 'solution') {
//         //   parent.appendChild(icon.element);
//         // } else {
//         //   parent.removeChild(icon.element);
//         // }

//     })
// }

export function fetchLayers(canvas, camera) {

    const parent = document.querySelector('.canvas-container');
    if (!parent) {
        return;
    }

    fetch(getUrl('markers'))
        .then(response => response.json())
        .then(markersJson => {

            markersJson.forEach((markerJson) => {

                // markers that are chainwide don't have to be visible on the canvas. So they don't
                // have to be split up by position. Hence, they don't have a phase also
                if (markerJson.chain_wide === true) {

                    const icon = new MarkerIcon(
                            {
                                id: markerJson.id,
                                url: markerJson.url,
                                description: markerJson.description,
                                chainWide: markerJson.chain_wide,
                                type: markerJson.type,
                                themes: markerJson.themes,
                                relations: markerJson.relations
                            });

                    markerIcons.push(icon)
                    chainWideMarkerIcons.push(icon);

                } else {

                    markerJson.positions.forEach(position => {

                        const icon = new MarkerIcon(
                            {
                                id: markerJson.id,
                                url: markerJson.url,
                                description: markerJson.description,
                                chainWide: markerJson.chain_wide,
                                type: markerJson.type,
                                themes: markerJson.themes,
                                phase: getPhase(position.x, position.y),
                                position: position,
                                relations: markerJson.relations
                            });


                        const [x, y] = SVGPositionToCanvasPosition(canvas, camera, position.x, position.y)
                        icon.changeRealPosition(x, y);

                        parent.appendChild(icon.element);

                        if (hasPhase(icon.phase)) {
                            phaseToMarkerDict[icon.phase].push(icon);
                        }
                        markerIcons.push(icon);
                        inPhaseMarkerIcons.push(icon);
                    });
                }

            });

        }).catch((error) => {
        console.log(`error: ${error}`);
    });

     fetch(getUrl('intros'))
        .then(response => response.json())
        .then(introsJson => {

            introsJson.forEach((introJson) => {

                let introText = [];
                introJson.body.forEach((bodyPart) => {
                    introText.push(bodyPart.value.content);

                });

                if (introDict[introJson.type] === null) {
                    introDict[introJson.type] = introText.join('');
                }

            });

             addContentToLandingPage();
             addContentToIntanet();

        }).catch((error) => {
        console.log(`error: ${error}`);
    });



}



export function updateLayers(canvas, camera) {
    inPhaseMarkerIcons.forEach(layerIcon => {
        const [x, y] = SVGPositionToCanvasPosition(canvas, camera, layerIcon.position.x, layerIcon.position.y);
        layerIcon.changeRealPosition(x, y);

        if (layerIcon.type === 'oplossing') {
            if (solutionsCheckbox.checked) {
                layerIcon.element.classList.add('visible');
            } else {
                layerIcon.element.classList.remove('visible');
            }
        }

        if (layerIcon.type === 'uitdaging') {
            if (challengesCheckbox.checked) {
                layerIcon.element.classList.add('visible');
            } else {
                layerIcon.element.classList.remove('visible');
            }
        }
    });
}
